import React, { useEffect, useState } from "react";
import thumbPlaceholder from "../../Common/_assets/img/placeholder/video-placeholder-n.jpg";
import { prepareProjectScreener } from "../../utils/api/helpers/indee";
import ReactGA from "react-ga4";

const ScreenerListItem = ({
	projectName,
	scr,
	setLoaded,
	projectKey,
	activeKey,
	activeScreener,
	setActiveScreener,
}) => {
	const [title, setTitle] = useState(null),
		[description, setDescription] = useState(null),
		[episodeNumber, setEpisodeNumber] = useState(null);

	useEffect(() => {
		let subscribed = true;
		if (subscribed && scr) {
			setDescription(scr.description ? truncate(scr.description) : "");
			setTitle(scr.name);
			setEpisodeNumber(scr.episode);
			// console.log('scr',projectKey, scr)
		}
		return () => {
			subscribed = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scr]);

	const truncate = (input) => {
		if (input.length > 5) {
			return input.substring(0, 130) + "...";
		}
		return input;
	};

	const prepareScreener = () => {
		prepareProjectScreener(activeKey, scr.key).then((result) => {
			scr.screening_details.screener_key =
				result.data.screening_details.screener_key;
			setActiveScreener(scr);
		});
	};

	const formatGAEventName = (str1, str2) => {
		const maxTotalLength = 39; // total chars excluding the pipe
		const maxStr1Length = 19;

		let truncatedStr1 = str1.slice(0, maxStr1Length);
		let remainingLength = maxTotalLength - truncatedStr1.length;
		let truncatedStr2 = str2.slice(0, remainingLength);

		return `${truncatedStr1}|${truncatedStr2}`;
	};

	return (
		<div
			onClick={() => {
				prepareScreener();
				const gaAction = formatGAEventName(projectName, "Screener");
				console.log(gaAction);
				ReactGA.event({
					category: "Screener View",
					action: gaAction,
					label: projectName,
				});
			}}
			className={
				"episodelist__item-container titledetail__mediaitem p-2 mb-2 d-flex " +
				(activeScreener.screening_details.screener_key ===
				scr.screening_details.screener_key
					? "active"
					: "")
			}
			style={{ cursor: "pointer" }}
		>
			<div className={"col-3"}>
				<img
					src={scr.poster ? scr.poster : thumbPlaceholder}
					alt={title}
				/>
			</div>
			<div className={"px-3"}>
				<div className={"episodelist__item-title"}>
					{episodeNumber
						? episodeNumber + " - " + title
						: projectName}
				</div>
				<div className={"episodelist__item-description"}>
					{description}
				</div>
			</div>
		</div>
	);
};

export default ScreenerListItem;
